import * as _ from "lodash";

export class Column {
  constructor(
    public field: string,
    public label?: string,
    public formatFunction?: CallableFunction,
    public sortable: boolean = true,
    public filterable: boolean = false,
    public classes?: string,
    public isHTML: boolean = false
  ) {
    if (typeof field === "string" && !label) {
      this.label = field;
    }
  }

  getField(obj: unknown): string {
    if (this.formatFunction) {
      return this.formatFunction(this, obj);
    }
    return _.get(obj, this.field);
  }
}
