<template>
  <b-overlay :show="isSending">
    <h1>Notifications</h1>
    <b-card class="mb-3">
      <b-form-group label="User" :disabled="sendToAll">
        <b-form-select
          name="user"
          v-model="selectedUser"
          v-validate="'required'"
          :options="users"
          text-field="email"
          value-field="id"
        ></b-form-select>
        <div v-if="submitted && errors.has('user')" class="alert-danger">
          {{ errors.first("user") }}
        </div>
      </b-form-group>
      <b-form-group>
        <b-form-checkbox id="checkbox-1" v-model="sendToAll">
          Send to everyone
        </b-form-checkbox>
      </b-form-group>
      <b-form-group label="Title">
        <b-form-input
          name="title"
          v-validate="'required|min:3|max:50'"
          v-model="title"
        ></b-form-input>
        <div v-if="submitted && errors.has('title')" class="alert-danger">
          {{ errors.first("title") }}
        </div>
      </b-form-group>
      <b-form-group label="Message">
        <quill-editor
          name="description"
          v-validate="'required|min:3|max:255'"
          v-model="message"
          :options="editorOption"
        />
        <div v-if="submitted && errors.has('description')" class="alert-danger">
          {{ errors.first("description") }}
        </div>
      </b-form-group>
      <b-form-group label="url">
        <b-form-input v-model="url" placeholder="https://..."></b-form-input>
      </b-form-group>
      <b-button :disabled="false" @click="sendNotification"
        >Send notification</b-button
      >
    </b-card>
    <notifications-table />
  </b-overlay>
</template>
<script>
import NotificationsTable from "@/components/admin/notifications/NotificationsTable.vue";

export default {
  name: "Notifications",
  components: {
    NotificationsTable,
  },
  data() {
    return {
      sendToAll: false,
      selectedUser: null,
      title: "",
      message: "",
      url: "",
      isSending: false,
      editorOption: {},
      submitted: false
    };
  },
  computed: {
    users() {
      return this.$store.getters["Users/all"]?.data;
    },
  },
  methods: {
    async fetchUsers() {
      return this.$store.dispatch("Users/fetchAll");
    },
    async sendNotification() {
      this.isSending = true;
      this.$validator.validateAll().then(async (isValid) => {
        this.submitted = true;
        if (!isValid) {
          this.successful = false
          this.isSending = false
          return;
        }
        const payload = {
          recipient: this.selectedUser,
          title: this.title,
          message: this.message,
          action_url: this.url,
          send_to_all: this.sendToAll,
        };
        await this.$store.dispatch("Notifications/create", payload);
        await this.$store.dispatch("Notifications/fetchAll");
        this.successful = true;
      });
      this.isSending = false;
    },
  },
  async created() {
    await this.fetchUsers();
  },
};
</script>
<style lang="scss"></style>
