<template>
  <b-overlay :show="isLoading" rounded="sm">
    <b-card body-class="p-0">
      <template #header v-if="showHeader">
        <template v-if="title">
          <h4>{{ title }}</h4>
        </template>
      </template>
      <div class="table-responsive">
        <table class="table table-sm  card-table table-hover">
          <thead>
            <tr>
              <th v-for="(column, index) in columns" :key="index">
                <a href="#" class="text-muted">
                  {{ column.label }}
                </a>
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(element, index) in elements" :key="index">
              <td
                v-for="(col, indexCol) in columns"
                :key="indexCol"
                :class="col.classes"
              >
                <template v-if="col.isHTML">
                  <span v-html="col.getField(element)"></span>
                </template>
                <template v-else>
                  {{ col.getField(element) }}
                </template>
              </td>
              <td class="text-right no-wrap">
                <button
                  v-for="button of customButtons"
                  :key="button.event"
                  @click="emit(button.event, element)"
                  :class="`btn btn-${button.variant} mr-2 btn-sm`"
                  href=""
                >
                  <span :class="`fe fe-${button.icon}`"></span>
                </button>
                <button
                  v-if="canUpdate"
                  @click="doEdit(element)"
                  href="#!"
                  class="btn btn-primary mr-2 btn-sm"
                >
                  <span class="fe fe-edit"></span>
                </button>
                <button
                  v-if="canDelete"
                  @click="doDelete(element)"
                  href="#!"
                  class="btn btn-danger btn-sm"
                >
                  <span class="fe fe-trash"></span>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </b-card>
    <b-pagination
      v-if="showPagination"
      @change="onPageChanged"
      :value="page"
      :total-rows="totalDocs"
      :per-page="limit"
    ></b-pagination>
  </b-overlay>
</template>
<script>
export default {
  name: "NotificationsTable",
  props: {
    title: String,
    columns: {
      type: Array,
      default: () => [],
    },
    canUpdate: {
      type: Boolean,
      default: false,
    },
    canDelete: {
      type: Boolean,
      default: false,
    },
    elements: {
      type: Array,
      default: () => [],
    },
    totalDocs: Number,
    page: Number,
    limit: Number,
    customButtons: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    showHeader() {
      return this.title;
    },
    showPagination() {
      return this.limit <= this.totalDocs;
    },
  },
};
</script>
<style lang="scss"></style>
